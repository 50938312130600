@-ms-viewport {
	width: device-width;
}

@viewport {
	width: device-width;
}

/* ===================================================== RESET ===================================================== */
* {
	margin: 0;
	padding: 0;

	color: inherit;
	font-family: inherit;
	font-size: 1em;
	text-align: inherit;
	line-height: inherit;
	direction: inherit;
	text-size-adjust: none;
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	text-rendering: optimizeLegibility;

	border-color: inherit;

	z-index: inherit;
}

html,
body {
	min-height: 100%;
}

html {
	overflow-y: auto;

	font-size: 10px;
}

body {
	margin: 0 auto;

	font-size: 1em;
	font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, a, span, em, strong, pre {
	color: inherit;
	line-height: inherit;
}

h1, h2, h3, h4, h5, h6, strong {
	font-weight: 700;
}

ul,
ol {
	margin-left: 20px;

	list-style-position: outside;

	ul,
	ol {
		font-size: 1em;
	}
}

ul {
	list-style-type: disc;
}

ol {
	list-style-type: decimal;
}

sub,
sup,
small {
	font-size: smaller;
}

img {
	display: inline-block;
}

a img {
	border: none;
}

body,
input,
button {
	-webkit-font-smoothing: antialiased;
}
/* ===================================================== RESPONSIVE ELEMENTS ===================================================== */
[class|="show-on"] {
	display: none;
}

.oldie .show-on-desktop {
	display: block;
}

.oldie .noshow-on-desktop {
	display: none;
}

/* ----------------------------------------------------- SMALL MOBILE (iPhone Portrait) ----------------------------------------------------- */
@media (max-width: breakpoint(mobile-large) - 1) {
	.noshow-on-mobile-s {
		display: none;
	}

	.show-on-mobile-s {
		display: block;
	}
}

/* ----------------------------------------------------- LARGE MOBILE (iPhone Landscape) ----------------------------------------------------- */
@media (min-width: breakpoint(mobile-large)) and (max-width: breakpoint(tablet-small) - 1) {
	.noshow-on-mobile-l {
		display: none;
	}

	.show-on-mobile-l {
		display: block;
	}
}

/* ----------------------------------------------------- MOBILE (iPhone) ----------------------------------------------------- */
@media (max-width: breakpoint(tablet-small) - 1) {
	.noshow-on-mobile {
		display: none;
	}

	.show-on-mobile {
		display: block;
	}
}

/* ----------------------------------------------------- SMALL TABLET ----------------------------------------------------- */
@media (min-width: breakpoint(tablet-small)) and (max-width: breakpoint(tablet-large) - 1) {
	.noshow-on-tablet-s {
		display: none;
	}

	.show-on-tablet-s {
		display: block;
	}
}

/* ----------------------------------------------------- LARGE TABLET (iPad) ----------------------------------------------------- */
@media (min-width: breakpoint(tablet-large)) and (max-width: breakpoint(desktop) - 1) {
	.noshow-on-tablet-l {
		display: none;
	}

	.show-on-tablet-l {
		display: block;
	}
}

/* ----------------------------------------------------- TABLET (iPad) ----------------------------------------------------- */
@media (min-width: breakpoint(tablet-small)) and (max-width: breakpoint(desktop) - 1) {
	.noshow-on-tablet {
		display: none;
	}

	.show-on-tablet {
		display: block;
	}
}

/* ----------------------------------------------------- DESKTOP ----------------------------------------------------- */
@media (min-width: breakpoint(desktop)) {
	.noshow-on-desktop {
		display: none;
	}

	.show-on-desktop {
		display: block;
	}
}

/* ===================================================== GROWING TEXT ===================================================== */
/* ----------------------------------------------------- MOBILE (iPhone) ----------------------------------------------------- */
@media (max-device-width: breakpoint(tablet-small) - 1) {
	html {
		&.grow-font {
			font-size: 12px;
		}
	}
}

/* ----------------------------------------------------- TABLET (iPad) ----------------------------------------------------- */
@media (min-device-width: breakpoint(tablet-small)) and (max-device-width: breakpoint(desktop) - 1) {
	html {
		&.grow-font {
			font-size: 11px;
		}
	}
}

/* ===================================================== CONDITIONAL VISIBILITY ===================================================== */
.hidden,
.no-show {
	display: none !important;
}

@media screen {
	.no-show-on-screen {
		display: none !important;
	}
}

@media print {
	.no-show-on-print {
		display: none !important;
	}
}
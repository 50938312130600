/* ===================================================== OTHER ===================================================== */
hr {
	width: 100%;
	height: 1px;
	margin: 1.5em 0;

	display: inline-block;

	clear: both;

	font-size: 1.3em;
	line-height: 1.25;

	//background-image: -webkit-gradient(linear, left top, right top, color-stop(0, colour-opacity($border-light-colour, 0)), color-stop(0.25, $border-light-colour),  color-stop(0.75, $border-light-colour), color-stop(1, colour-opacity($border-light-colour, 0)));
	background-image: -webkit-linear-gradient(right, colour-opacity($border-light-colour, 0) 0%, $border-light-colour 25%, $border-light-colour 75%, colour-opacity($border-light-colour, 0) 100%);
	background-image: -moz-linear-gradient(right, colour-opacity($border-light-colour, 0) 0%, $border-light-colour 25%, $border-light-colour 75%, colour-opacity($border-light-colour, 0) 100%);
	//background-image: -ms-linear-gradient(right, colour-opacity($border-light-colour, 0) 0%, $border-light-colour 25%, $border-light-colour 75%, colour-opacity($border-light-colour, 0) 100%);
	//background-image: -o-linear-gradient(right, colour-opacity($border-light-colour, 0) 0%, $border-light-colour 25%, $border-light-colour 75%, colour-opacity($border-light-colour, 0) 100%);
	background-image: linear-gradient(to right, colour-opacity($border-light-colour, 0) 0%, $border-light-colour 25%, $border-light-colour 75%, colour-opacity($border-light-colour, 0) 100%);
	background-color: $border-light-colour;

	border: none;
}

p {
	+ hr {
		margin-top: 1em;
	}
}

ul,
ol,
dl {
	+ hr {
		margin-top: 0;
	}
}

/* ===================================================== GENERIC CLASSES ===================================================== */
.float-right,
.float-left {
	display: inline-block;
}

.float-right {
	float: right !important;
}

.float-left {
	float: left !important;
}

.float-none {
	float: none !important;
}

.clear-right {
	clear: right !important;
}

.clear-left {
	clear: left !important;
}

.clear-none {
	clear: none !important;
}

.align-left {
	text-align: left !important;
}

.align-right {
	text-align: right !important;
}

.align-center {
	text-align: center !important;
}

.align-justify {
	text-align: justify !important;
}

.no-top-margin {
	margin-top: 0 !important;
}

.no-bottom-margin {
	margin-bottom: 0 !important;
}

.no-vertical-margin {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.no-top-padding {
	padding-top: 0 !important;
}

.no-bottom-padding {
	padding-bottom: 0 !important;
}

.no-vertical-padding {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.no-vertical-spacing {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.no-wrap {
	white-space: nowrap !important;
}

/* ===================================================== NOTICES ===================================================== */
.error,
.warning,
.notice,
.success {
	min-height: 16px;
	margin-bottom: 10px;
	padding: 6px 9px;

	position: relative;

	border: 1px #000 solid;
	border-radius: $border-radius;

	> :first-child {
		margin-top: 0;
		padding-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

p {
	&.error,
	&.warning,
	&.notice,
	&.success {
		padding: 0.5em 0.75em;
	}
}

.error {
	background-color: map-get($error, "background");

	border-color: map-get($error, "border");
}

.warning {
	background-color: map-get($warning, "background");

	border-color: map-get($warning, "border");
}

.success {
	background-color: map-get($success, "background");

	border-color: map-get($success, "border");
}

.notice {
	background-color: map-get($notice, "background");

	border-color: map-get($notice, "border");
}

.callout {
	background-color: $background-light;

	border-width: 0 0 0 4px;
	border-radius: 0 $border-radius $border-radius 0;

	&.error {
		border-color: map-get($error, "border");
	}

	&.warning {
		border-color: map-get($warning, "border");
	}

	&.success {
		border-color: map-get($success, "border");
	}

	&.notice {
		border-color: map-get($notice, "border");
	}
}

/* ===================================================== TAGS ===================================================== */
.tag {
	padding: #{((base-font(line-height) - 1) / 2)}em 0.35em;

	display: inline-block;

	color: #FFF;
	font-weight: 700;
	font-size: 0.875em;
	line-height: 1;
	vertical-align: baseline;

	background-color: $tag-base;

	border-radius: $border-radius;

	@each $tag-colour-name, $tag-hex in $tag-colours {
		&.#{$tag-colour-name} {
			background-color: $tag-hex;
		}
	}

	&.caps {
		text-transform: uppercase !important;
	}

	&.large {
		font-size: 1.1em;
	}

	&.huge {
		font-size: 1.325em;
	}

	&.inherit {
		font-size: 1em;
	}

	a {
		&.cross {
			width: 11px;
			height: 11px;
			margin: 0 0 0 0.35em;

			display: inline-block;

			position: relative;
			//top: 3.5px;

			font-size: 1em;
			text-align: center;
			font-weight: 700;
			line-height: 1;
			text-decoration: none;
			//vertical-align: top;

			background-color: #FFF;

			border-radius: 100%;

			&::before,
			&::after {
				display: block;

				position: absolute;

				background-color: $tag-cross;

				-webkit-transform-origin: 50% 50%;
				transform-origin: 50% 50%;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);

				content: "";
			}

			&::before {
				width: 1px;
				height: 7px;
				top: 2px;
				left: 5px;
			}

			&::after {
				width: 7px;
				height: 1px;
				top: 5px;
				left: 2px;
			}
		}
	}
}
/* ===================================================== GENERIC ===================================================== */
html {
	background-color: #FFF;
}

body {
	@include base-font-colour;
	@include base-font-face;
	@include base-font-weight;
}

.clear {
	width: 0;
	height: 0;

	display: block !important;
	visibility: hidden !important;

	clear: both !important;

	overflow: hidden;
}
/* ===================================================== BUTTONS ===================================================== */
a.button,
label.button {
	@include button;

	&.center {
		margin-right: auto;
		margin-left: auto;

		display: block;
	}
}

label.button {
	width: auto !important;
	padding: 0 0.75em !important;

	input[type="checkbox"] {
		margin: 0 0 0 0.5em !important;

		display: inline-block !important;

		float: none !important;

		vertical-align: baseline;
	}

	&.left {
		input[type="checkbox"] {
			margin-right: 0.5em !important;
			margin-left: 0 !important;
		}
	}
}

@media all and (-webkit-min-device-pixel-ratio: 0) {
	label.button {
		input[type="checkbox"] {
			//vertical-align: baseline;
		}
	}
}
/* ===================================================== TABLES ===================================================== */
table {
	width: 100%;
	margin-bottom: #{base-font(line-height)}em;

	clear: both;

	@include base-font-size;

	background-color: rgba(255, 255, 255, 0.85);

	border: 0 table-colour("border") solid;
	border-collapse: collapse;

	&.verticals {
		th,
		td {
			border-right-width: 1px;
			border-left-width: 1px;
		}
	}

	&.plain,
	&.no-horizontals {
		th,
		td {
			border-top-width: 0;
			border-bottom-width: 0;
		}
	}

	&.plain {
		tbody {
			tr {
				background-color: transparent !important;
			}
		}
	}

	&.highlight-on-hover {
		tfoot,
		tbody {
			tr {

				-webkit-transition: background ease 200ms;
				-moz-transition: background ease 200ms;
				-ms-transition: background ease 200ms;
				-o-transition: background ease 200ms;
				transition: background ease 200ms;

				&:hover {
					background-color: table-colour(hover);

					-webkit-transition: background ease 50ms;
					-moz-transition: background ease 50ms;
					-ms-transition: background ease 50ms;
					-o-transition: background ease 50ms;
					transition: background ease 50ms;
				}
			}
		}
	}

	&.large {
		font-size: #{base-font(size) * exponent(1.2, 1)}em;
	}

	&.zebra {
		tbody {
			tr {
				&:nth-child(even) {
					background-color: $background-light;
				}
			}
		}
	}

	&.striped {
		tfoot,
		tbody {
			th,
			td {
				&:nth-child(even) {
					background-color: $background-light;
				}
			}
		}
	}

	p,
	ul,
	ol,
	button,
	.button {
		font-size: 1em;
	}

	p {
		margin: 0.25em 0;

		@include base-font-line-height;
	}

	ul {
		margin-left: 1.5em;
	}
}

	thead,
	tfoot,
	tbody {
		&.large {
			font-size: #{base-font(size) * exponent(1.2, 1)}em;
		}

		tr {
			&.highlight {
				th,
				td {
					background-color: table-colour(highlight) !important;
				}
			}
		}
	}

	thead {
		color: blackorwhite(table-colour(header));
		font-weight: 700;

		background-color: table-colour(header);

		border-width: 1px 1px 0;
		border-color: table-colour(header-border);
		border-style: solid;
	}

	tfoot,
	tbody {
		border-width: 0 1px 1px;
		border-color: inherit;
		border-style: solid;
	}

	tbody {
		tr {
			&.disabled {
				> * {
					color: form-colour("disabled");
				}
			}

			&.highlight-fade {
				th,
				td {
					-webkit-animation: highlight-fade 6s;
					-moz-animation: highlight-fade 6s;
					-ms-animation: highlight-fade 6s;
					-o-animation: highlight-fade 6s;
					animation: highlight-fade 6s;
				}
			}
		}
	}

			th,
			td {
				padding: 0.5em 0.75em;

				text-align: left;

				border-width: 0 0 1px;
				border-color: inherit;
				border-style: solid;

				&.no-padding {
					padding: 0;
				}

				&.no-padding-x {
					padding-right: 0;
					padding-left: 0;
				}

				&.no-padding-y {
					padding-top: 0;
					padding-bottom: 0;
				}
			}

table,
tr {
	&.no-padding {
		th,
		td {
			padding: 0;
		}
	}

	&.no-padding-x {
		th,
		td {
			padding-right: 0;
			padding-left: 0;
		}
	}

	&.no-padding-y {
		th,
		td {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

form {
	table {
		label,
		input {
			font-size: 1em;
		}
	}
}

@include keyframes(highlight-fade) {
	0% {
		background-color: transparent;
	}

	10% {
		background-color: table-colour(highlight);
	}

	75% {
		background-color: table-colour(highlight);
	}

	100% {
		background-color: transparent;
	}
}
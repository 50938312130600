/* ===================================================== MODERNIZR ===================================================== */
.js .hide-on-js,
.flexbox .hide-on-flexbox,
.canvas .hide-on-canvas,
.canvastext .hide-on-canvastext,
.webgl .hide-on-webgl,
.touch .hide-on-touch,
.geolocation .hide-on-geolocation,
.postmessage .hide-on-postmessage,
.websqldatabase .hide-on-websqldatabase,
.indexeddb .hide-on-indexeddb,
.hashchange .hide-on-hashchange,
.history .hide-on-history,
.draganddrop .hide-on-draganddrop,
.websockets .hide-on-websockets,
.rgba .hide-on-rgba,
.hsla .hide-on-hsla,
.multiplebgs .hide-on-multiplebgs,
.backgroundsize .hide-on-backgroundsize,
.borderimage .hide-on-borderimage,
.borderradius .hide-on-borderradius,
.boxshadow .hide-on-boxshadow,
.textshadow .hide-on-textshadow,
.opacity .hide-on-opacity,
.cssanimations .hide-on-cssanimations,
.csscolumns .hide-on-csscolumns,
.cssgradients .hide-on-cssgradients,
.cssreflections .hide-on-cssreflections,
.csstransforms .hide-on-csstransforms,
.csstransforms3d .hide-on-csstransforms3d,
.csstransitions .hide-on-csstransitions,
.fontface .hide-on-fontface,
.generatedcontent .hide-on-generatedcontent,
.video .hide-on-video,
.audio .hide-on-audio,
.localstorage .hide-on-localstorage,
.sessionstorage .hide-on-sessionstorage,
.webworkers .hide-on-webworkers,
.applicationcache .hide-on-applicationcache,
.svg .hide-on-svg,
.inlinesvg .hide-on-inlinesvg,
.smil .hide-on-smil,
.svgclippaths .hide-on-svgclippaths,
.no-js .hide-on-no-js,
.no-flexbox .hide-on-no-flexbox,
.no-canvas .hide-on-no-canvas,
.no-canvastext .hide-on-no-canvastext,
.no-webgl .hide-on-no-webgl,
.no-touch .hide-on-no-touch,
.no-geolocation .hide-on-no-geolocation,
.no-postmessage .hide-on-no-postmessage,
.no-websqldatabase .hide-on-no-websqldatabase,
.no-indexeddb .hide-on-no-indexeddb,
.no-hashchange .hide-on-no-hashchange,
.no-history .hide-on-no-history,
.no-draganddrop .hide-on-no-draganddrop,
.no-websockets .hide-on-no-websockets,
.no-rgba .hide-on-no-rgba,
.no-hsla .hide-on-no-hsla,
.no-multiplebgs .hide-on-no-multiplebgs,
.no-backgroundsize .hide-on-no-backgroundsize,
.no-borderimage .hide-on-no-borderimage,
.no-borderradius .hide-on-no-borderradius,
.no-boxshadow .hide-on-no-boxshadow,
.no-textshadow .hide-on-no-textshadow,
.no-opacity .hide-on-no-opacity,
.no-cssanimations .hide-on-no-cssanimations,
.no-csscolumns .hide-on-no-csscolumns,
.no-cssgradients .hide-on-no-cssgradients,
.no-cssreflections .hide-on-no-cssreflections,
.no-csstransforms .hide-on-no-csstransforms,
.no-csstransforms3d .hide-on-no-csstransforms3d,
.no-csstransitions .hide-on-no-csstransitions,
.no-fontface .hide-on-no-fontface,
.no-generatedcontent .hide-on-no-generatedcontent,
.no-video .hide-on-no-video,
.no-audio .hide-on-no-audio,
.no-localstorage .hide-on-no-localstorage,
.no-sessionstorage .hide-on-no-sessionstorage,
.no-webworkers .hide-on-no-webworkers,
.no-applicationcache .hide-on-no-applicationcache,
.no-svg .hide-on-no-svg,
.no-inlinesvg .hide-on-no-inlinesvg,
.no-smil .hide-on-no-smil,
.no-svgclippaths .hide-on-no-svgclippaths {
	display: none !important;
}